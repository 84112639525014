// extracted by mini-css-extract-plugin
export var cls1 = "contacto-ill-module--cls-1--095e5";
export var cls10 = "contacto-ill-module--cls-10--058bc";
export var cls11 = "contacto-ill-module--cls-11--f7c4b";
export var cls2 = "contacto-ill-module--cls-2--53698";
export var cls3 = "contacto-ill-module--cls-3--aee4e";
export var cls4 = "contacto-ill-module--cls-4--f254d";
export var cls5 = "contacto-ill-module--cls-5--b9d31";
export var cls6 = "contacto-ill-module--cls-6--4eaf3";
export var cls7 = "contacto-ill-module--cls-7--62aeb";
export var cls8 = "contacto-ill-module--cls-8--ab9b8";
export var cls9 = "contacto-ill-module--cls-9--850ea";