import React, { useEffect } from "react";

import gsap from "gsap";
import * as styles from "./contacto-ill.module.css";

type CustomSVGSProps = Omit<React.SVGProps<SVGSVGElement>, "xmlns" | "viewBox">;

export const ContactoIll = ({ className, ...rest }: CustomSVGSProps) => {
  useEffect(() => {
    const planta = gsap.timeline({
      defaults: { duration: 2, repeat: -1, yoyoEase: true },
    });
    const pc = gsap.timeline({ defaults: { duration: 2 } });

    planta
      .to("#hoja-2", {
        rotate: 2,
        transformOrigin: "rigth bottom",
      })
      .to(
        "#hoja-1",
        {
          rotate: 3,
          transformOrigin: "rigth bottom",
        },
        "<"
      )
      .to(
        "#hoja-2-2",
        {
          rotate: -3,
          transformOrigin: "left bottom",
        },
        "<"
      );

    pc.fromTo(
      "#centro-pc",
      {
        scale: 1,
        fill: "red",
      },
      {
        scale: 1.2,
        fill: "green",
        repeat: -1,
        yoyoEase: true,
      }
    );

    gsap.to(".cuadro", {
      y: () => Math.random() * 2.5 + 1.8,
      duration: () => Math.random() * 2.5 + 1.4,
      repeat: -1,
      yoyoEase: true,
    });

    gsap
      .to(".ojo", {
        rotate: -15,
        transformOrigin: "right bottom",
        repeat: -1,
        yoyoEase: true,
      })
      .repeatDelay(4);
  }, []);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 542.53 493.47"
      id="contacto-ill"
      className={className || ""}
      {...rest}
    >
      <g id="Capa_2" data-name="Capa 2">
        <g id="OBJECTS">
          <path
            className={`${styles.cls1} _cls-1`}
            d="M525.07,486.59s29.61-26.76,1.55-60.43c-23.24-27.89-18.3-38.7-12.4-89.87,4.65-40.29-39.37-96.78-91.41-86.77-40.29,7.75-75.94-47.08-125.51-48-80.57-1.55-117.76,13.94-131.7,60.43C148.7,318.23,99,279,63.33,327c-25.65,34.58,10.85,62-3.1,94.52s-23.56,60.94-23.56,60.94l445,8.79"
          />
          <g id="planta">
            <g id="hojas">
              <g id="hoja-2">
                <path
                  className={`${styles.cls2} _cls-2`}
                  d="M482,413.63S471.73,394.21,462.53,388s-3.68-15.47-4.59-21.75,3-12.9,1.27-26.15,2.29-21.24,10-13.07,20.16,16.71,16.58,27.49,4.83,9.34,7.59,18.52-5.94,14.75-5.92,23.73S482,413.63,482,413.63Z"
                />
                <path
                  className={`${styles.cls3} _cls-3`}
                  d="M482,413.63s2.13-19.18-4.44-32.79-6.37-16.36-5.65-26-1.72-13.94-1.72-13.94"
                />
              </g>
              <g id="hoja-2-2" data-name="hoja-2">
                <path
                  className={`${styles.cls2} _cls-2`}
                  d="M488.73,412.3s5.1-16,12.7-15.88c8.38.13,13.93-7.26,19.28-2.09s16.93,10.79,20.76,5.19-3.26-16.69-11.88-23.16-12.43-8.57-20.2-4.39-21.57,18.46-23.05,26.59A19.53,19.53,0,0,0,488.73,412.3Z"
                />
                <path
                  className={`${styles.cls3} _cls-3`}
                  d="M488.73,412.3s-.11-14.72,7.35-21.06,8.14-13.92,16.39-13.11S533,388.18,533,388.18"
                />
              </g>
              <g id="hoja-1">
                <path
                  className={`${styles.cls2} _cls-2`}
                  d="M480.05,412.1s-11.58-11.23-11.86-16.77-5.78-14.94-12.5-17.51-15.6-12.91-19.6-5.62,7.19,5.38,6.11,19.8S480.05,412.1,480.05,412.1Z"
                />
                <path
                  className={`${styles.cls3} _cls-3`}
                  d="M480.05,412.1s-17.92-9.63-22.11-18.23a35.15,35.15,0,0,0-12.12-13.34"
                />
              </g>
            </g>
            <g id="maceta">
              <circle
                className={`${styles.cls4} _cls-4`}
                cx="482.47"
                cy="453.7"
                r="31.33"
              />
              <rect
                className={`${styles.cls4} _cls-4`}
                x="472.32"
                y="409.98"
                width="20.29"
                height="20.66"
              />
            </g>
          </g>
          <path
            className={`${styles.cls5} _cls-5`}
            d="M302.46,112.14s31-3.1,36.16,45.45,7.23,69.21,31,83.67,1,36.15,26.85,47.51,3.1,42.36-23.75,35.13-157,14.46-180.77-12.4,14.46-33.06,16.52-64-8.9-25.16,10-52.35,7.55-91.24,84-83"
          />
          <path
            className={`${styles.cls6} _cls-6`}
            d="M425.9,488.59l-246.16,3.33s-14-156.21,35.63-213.54c52.14-60.28,108.62-34,152.88-2.58C423.52,315.05,425.9,488.59,425.9,488.59Z"
          />
          <rect
            className={`${styles.cls2} _cls-2`}
            x="195.23"
            y="470.05"
            width="99.17"
            height="12.4"
            rx="3.35"
            transform="translate(489.63 952.5) rotate(-180)"
          />
          <path
            className={`${styles.cls4} _cls-4`}
            d="M214.73,177.34c0,35.39,11,63.13,37.83,63.13S312,212.73,312,177.34s-32.55-65-59.4-65S214.73,142,214.73,177.34Z"
          />
          <path
            className={`${styles.cls4} _cls-4`}
            d="M291.3,213.3c3.76,19.48,12.9,31.82,28.66,35.38L278.9,298s-30.21-40.28-31.76-46c-.31-1.12,14.2-8.27,14.2-8.27l-5.68-18.36Z"
          />
          <path
            className={`${styles.cls7} _cls-7`}
            d="M408.17,352.13s19.11,67.66,8.27,90.9-129.79,16.35-129.79,16.35l-6.2-20.14s78.83-16.18,85-16.18c3.62,0-4.14-26.17-6.35-53.61a355.26,355.26,0,0,0-6.05-40.05"
          />
          <path
            className={`${styles.cls4} _cls-4`}
            d="M280.45,439.24s-20.66,1.54-28.92,6.71-12.91,10.85-9.81,11.88,9.81,4.13,13.94,1,23.76,3.62,31,.52-6.2-20.14-6.2-20.14"
          />
          <path
            className={`${styles.cls2} _cls-2`}
            d="M36.67,482.45H203.91a5.7,5.7,0,0,0,5.6-6.71L189.88,367.81a5.69,5.69,0,0,0-5.6-4.67H17a5.68,5.68,0,0,0-5.59,6.71L31.07,477.78A5.69,5.69,0,0,0,36.67,482.45Z"
          />
          <path
            className={`${styles.cls6} _cls-6`}
            d="M27.37,482.45H194.61a5.7,5.7,0,0,0,5.6-6.71L180.59,367.81a5.7,5.7,0,0,0-5.6-4.67H7.75a5.69,5.69,0,0,0-5.6,6.71L21.77,477.78A5.69,5.69,0,0,0,27.37,482.45Z"
          />
          <rect
            className={`${styles.cls2} _cls-2`}
            y="482.62"
            width="540.76"
            height="10.85"
            transform="translate(540.76 976.09) rotate(180)"
          />
          <path
            className={`${styles.cls5} _cls-5`}
            d="M273.05,101.45c27.43,5,57.76,32.54,44.59,58.88S296.93,176,296.93,176s-21.13-20.34-24.23-42c0,0,.13,12.3-31.18,14.3-34.67,2.21-23.76,39.25-23.76,39.25S189,189,192.13,163.72,194.54,87.11,273.05,101.45Z"
          />
          <path
            className={`${styles.cls5} _cls-5`}
            d="M311.13,196l-1.79-.47c-.06.22-5.58,20.33-22.24,24.84a5.83,5.83,0,1,0,.41,2.13c0-.11,0-.21,0-.32C305.24,217.48,311.07,196.26,311.13,196Z"
          />
          <path
            className={`${styles.cls7} _cls-7`}
            d="M242.23,458.35s2.9-6,8.52-8.78,6.72-2.85,6.72-2.85"
          />
          <path
            className={`${styles.cls7} _cls-7`}
            d="M246.88,459.64s2.94-6.23,9-7.75c4.13-1,5.42-1.29,5.42-1.29"
          />
          <g id="audifonos">
            <path
              className={`${styles.cls1} _cls-1`}
              d="M267.2,101.11s23.75-1,29.26,25.82,2.76,37.19,2.76,37.19l18.33-1.92s-.43-26.31-4.56-39.4c-6.06-19.18-21.35-21-21.35-21Z"
            />
            <rect
              className={`${styles.cls1} _cls-1`}
              x="290.34"
              y="152.59"
              width="40.65"
              height="48.03"
              rx="10.98"
              transform="translate(621.33 353.21) rotate(180)"
            />
            <rect
              className={`${styles.cls5} _cls-5`}
              x="301.58"
              y="161.86"
              width="24.37"
              height="28.8"
              rx="6.58"
              transform="translate(627.53 352.52) rotate(-180)"
            />
          </g>
          <path
            className={`${styles.cls7} _cls-7`}
            d="M221.4,330.89s-11.76,33.38-4,48.7c11.59,22.92,4.7,87-9.07,93.16"
          />
          <g id="cara">
            <path
              id="nariz"
              className={`${styles.cls8} _cls-8`}
              d="M244.57,175.6s-10,16.23-8,17.89,9.28,2.77,9.28,2.77"
            />
            <circle
              id="chapa-2"
              className={`${styles.cls9} _cls-9`}
              cx="274.08"
              cy="195.28"
              r="10.5"
            />
            <ellipse
              id="chapa-1"
              className={`${styles.cls9} _cls-9`}
              cx="222.26"
              cy="195.11"
              rx="3.79"
              ry="8.26"
            />
            <path
              id="boca"
              className={`${styles.cls10} _cls-10`}
              d="M234.66,206.07s15.84-2.75,22-2.07,0,17.05-10.33,18.08S234.66,206.07,234.66,206.07Z"
            />
            <g id="cejas">
              <path
                id="ceja-2"
                className={`${styles.cls5} _cls-5`}
                d="M250.59,160.31l.84,1.65c.1-.05,10.07-5,19.85-.44l.78-1.68C261.45,154.88,251,160.09,250.59,160.31Z"
              />
              <path
                id="ceja-1"
                className={`${styles.cls5} _cls-5`}
                d="M220.83,160l1.14,1.46c.23-.18,5.92-4.39,15.57.11l.78-1.68C227.6,154.84,221.1,159.73,220.83,160Z"
              />
            </g>
            <g id="ojos">
              <path
                id="ojjo-2"
                className={`${styles.cls5} _cls-5 ojo`}
                d="M263.22,172.77a2.3,2.3,0,0,0-.74.13,13,13,0,0,1-3.44-1.8.46.46,0,0,0-.65.09.47.47,0,0,0,.09.65,15.45,15.45,0,0,0,3,1.67,5.22,5.22,0,0,0-1.52,3.89c0,2.55,1.47,4.63,3.29,4.63s3.29-2.08,3.29-4.63S265,172.77,263.22,172.77Z"
              />
              <path
                id="ojo-1"
                className={`${styles.cls5} _cls-5 ojo`}
                d="M229.64,172.77a2.31,2.31,0,0,0-.85.18,17.19,17.19,0,0,1-3.55-2.6.46.46,0,0,0-.65,0,.47.47,0,0,0,0,.65,19.62,19.62,0,0,0,3.26,2.48,5.29,5.29,0,0,0-1.52,3.89c0,2.55,1.48,4.63,3.29,4.63s3.29-2.08,3.29-4.63S231.46,172.77,229.64,172.77Z"
              />
            </g>
          </g>
          <path
            className={`${styles.cls7} _cls-7`}
            d="M291.1,365.21s-39.25,36.16-22.72,30,34.08-13.43,25.82-4.13-13.43,11.36-13.43,11.36"
          />
          <ellipse
            id="centro-pc"
            className={`${styles.cls4} _cls-4`}
            cx="96.07"
            cy="424.54"
            rx="12.04"
            ry="13.43"
            transform="translate(-52.25 15.31) rotate(-7.17)"
          />
          <g id="cuadros">
            <rect
              id="cuadro-7"
              className={`${styles.cls1} _cls-1 cuadro`}
              x="316.73"
              y="17.44"
              width="31.59"
              height="32.61"
              rx="3.13"
              transform="translate(121.26 -225.24) rotate(45)"
            />
            <rect
              id="cuadro-6"
              className={`${styles.cls1} _cls-1 cuadro`}
              x="104.06"
              y="110.69"
              width="20.8"
              height="21.48"
              rx="2.06"
              transform="translate(119.39 -45.37) rotate(45)"
            />
            <rect
              id="cuadro-5"
              className={`${styles.cls1} _cls-1 cuadro`}
              x="413.12"
              y="206.18"
              width="20.8"
              height="21.48"
              rx="2.06"
              transform="translate(277.43 -235.94) rotate(45)"
            />
            <rect
              id="cuadro-4"
              className={`${styles.cls1} _cls-1 cuadro`}
              x="365.72"
              y="163.97"
              width="14.26"
              height="14.72"
              rx="1.41"
              transform="translate(230.36 -213.46) rotate(45)"
            />
            <rect
              id="cuadro-3"
              className={`${styles.cls1} _cls-1 cuadro`}
              x="140.44"
              y="58.42"
              width="14.26"
              height="14.72"
              rx="1.41"
              transform="translate(89.74 -85.08) rotate(45)"
            />
            <rect
              id="cuadro-2"
              className={`${styles.cls1} _cls-1 cuadro`}
              x="164.41"
              y="189.62"
              width="14.26"
              height="14.72"
              rx="1.41"
              transform="translate(189.53 -63.6) rotate(45)"
            />
            <rect
              id="cuadro-1"
              className={`${styles.cls1} _cls-1 cuadro`}
              x="482.68"
              y="171.94"
              width="35.01"
              height="36.13"
              rx="3.47"
              transform="translate(280.86 -298.03) rotate(45)"
            />
          </g>
          <g id="circulos">
            <g id="circulo-3">
              <circle
                className={`${styles.cls11} _cls-11`}
                cx="94.11"
                cy="224.43"
                r="49.64"
              />
              <path
                className={`${styles.cls5} _cls-5`}
                d="M88.8,218.26l11.57,10.53L97.55,232a5.86,5.86,0,0,0,.51,8.28l5.92,5.24a5.89,5.89,0,0,0,8.29-.51l1.54-1.75a12.89,12.89,0,0,0-1.12-18.19L91,205.58A12.88,12.88,0,0,0,72.8,206.7l-1.54,1.74a5.87,5.87,0,0,0,.51,8.29L77.69,222a5.87,5.87,0,0,0,8.29-.51Z"
              />
            </g>
            <g id="circulo-2">
              <circle
                className={`${styles.cls11} _cls-11`}
                cx="424.66"
                cy="99.05"
                r="54.91"
              />
              <polygon
                className={`${styles.cls5} _cls-5`}
                points="453.02 114.4 453.01 83.99 437.97 99.37 453.02 114.4"
              />
              <path
                className={`${styles.cls5} _cls-5`}
                d="M432.14,105.33a8.6,8.6,0,0,1-6.12,2.6,9,9,0,0,1-1.65-.15,8.61,8.61,0,0,1-4.5-2.39L416.48,102l-16.43,14.62,51.73,0a1.28,1.28,0,0,0,.47-.1l-15.7-15.68Z"
              />
              <polygon
                className={`${styles.cls5} _cls-5`}
                points="398.5 84 398.52 115.27 415.04 100.56 398.5 84"
              />
              <path
                className={`${styles.cls5} _cls-5`}
                d="M431,103.88l20-20.44-50.43,0L421,103.93A7,7,0,0,0,431,103.88Z"
              />
            </g>
            <g id="circulo-1">
              <circle
                className={`${styles.cls11} _cls-11`}
                cx="222.16"
                cy="38.38"
                r="37.38"
              />
              <path
                className={`${styles.cls5} _cls-5`}
                d="M225,43.35l-.23,4a2.94,2.94,0,0,1-3.18,2.83A3,3,0,0,1,218.79,47l.37-6.38c0-.06.07,0,.07-.12s-.06-.06,0-.12a3.09,3.09,0,0,1,3.17-2.83,6.88,6.88,0,1,0,.8-13.73,6.8,6.8,0,0,0-6.08,3,3,3,0,0,1-4.23.86,3,3,0,0,1-.81-4.16,12.9,12.9,0,1,1,13,19.81Zm-.78,13.42-.06,1.11A2.93,2.93,0,0,1,221,60.7a3,3,0,0,1-2.83-3.17l.07-1.11a3,3,0,0,1,3.17-2.82A3,3,0,0,1,224.25,56.77Z"
              />
            </g>
          </g>
          <polyline
            className={`${styles.cls7} _cls-7`}
            points="237.39 255.72 278.71 313.56 330.35 250.55"
          />
        </g>
      </g>
    </svg>
  );
};
