import React from "react";
import { Map } from "../components";

import { ContactoIll } from "../components/SVG/contacto-ill";
import { Layout } from "../components/themed";
import { ContactForm } from "../components/themed/ContactForm";

const Contacto = () => {
  return (
    <Layout>
      <div className="grid grid-cols-1 auto-rows-min md:grid-cols-2 items-center">
        <ContactoIll className="w-[80%] mx-auto my-10" />
        <div className="">
          <div className="text-center w-[90%] md:w-[85%] mx-auto font-poppins md:my-6">
            <h2 className="font-semibold text-3xl md:text-4xl mb-1">
              ¡Cotiza ahora!
            </h2>
            <h1 className="font-extrabold text-5xl md:text-7xl mb-3">
              Contáctanos
            </h1>
            <p className="text-lg md:text-xl">
              Comunícate con nosotros y te haremos llegar la información que
              deseas.
            </p>
          </div>
          <ContactForm className="my-[50px] w-[85%] md:w-[80%] mx-auto" />
        </div>
      </div>
      <div className="h-28"></div>
      <Map className="mb-14" />
    </Layout>
  );
};

export default Contacto;

// type FormControlProps = {
//   name: string;
//   children: ReactNode | ReactNode[];
// };

// type FormControlContextProps<T = string> = FormikFieldInputProps<T> &
//   FormikFieldMetaProps<T> & { name: string };

// const FormControlContext = createContext<FormControlContextProps>(
//   {} as FormControlContextProps
// );

// const useFormControl = () => useContext(FormControlContext);

// export const FormControl = ({ children, name }: FormControlProps) => {
//   const [field, meta] = useField<string>(name);

//   return (
//     <div>
//       <FormControlContext.Provider value={{ ...field, ...meta }}>
//         {children}
//       </FormControlContext.Provider>
//     </div>
//   );
// };

// type FieldInputProps = Exclude<
//   React.DetailedHTMLProps<
//     React.InputHTMLAttributes<HTMLInputElement>,
//     HTMLInputElement
//   >,
//   "name" | "value"
// > & {};

// const Field = ({
//   onBlur: onBlurProp,
//   onChange: onChangeProp,
//   ...props
// }: FieldInputProps) => {
//   const { name, value, onChange, onBlur } = useFormControl();

//   const blurEvent = (event: React.FocusEvent<HTMLInputElement>) => {
//     onBlur(event);
//     onBlurProp?.(event);
//   };
//   const changeEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
//     onChange(event);
//     onChangeProp?.(event);
//   };

//   return (
//     <input
//       name={name}
//       value={value}
//       onBlur={blurEvent}
//       onChange={changeEvent}
//       {...props}
//     />
//   );
// };

// type FieldLabelProps = { children: ReactNode } & Exclude<
//   JSX.IntrinsicElements["label"],
//   "children" | "htmlFor"
// >;

// const FieldLabel = ({ children, ...props }: FieldLabelProps) => {
//   const { name } = useFormControl();

//   return (
//     <label htmlFor={name} {...props}>
//       {children}
//     </label>
//   );
// };
